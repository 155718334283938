import { useLocation } from '@remix-run/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Body from '#app/components/typography/body'
import Heading from '#app/components/typography/heading'
import { Icon } from '#app/components/ui/icon'
import { Sheet, SheetContent, SheetTrigger } from '#app/components/ui/sheet'
import { cn } from '#app/utils/misc'
import { type NavigationCategoriesStoryblok } from '#types/component-types-sb'
import LanguageMenu from './language-menu'
import { ResponsiveNavItem, ResponsiveNavLinkItem } from './responsive-nav-item'
import SubNavigationMenu from './sub-navigation-menu'

interface ResponsiveNavbarProps {
	navItems: {
		link: string
		name: string
	}[]
	navigationData: NavigationCategoriesStoryblok[]
}

export enum ResponsiveOptions {
	Main = 'Main',
	Women = 'Women',
	Man = 'Man',
	Kids = 'Kids',
	Brands = 'Brands',
	Language = 'Language',
}
const ResponsiveNavbar = ({
	navItems,
	navigationData,
}: ResponsiveNavbarProps) => {
	const [open, setOpen] = useState(false)

	const [selectedOption, setSelectedOption] = useState<ResponsiveOptions>(
		ResponsiveOptions.Main,
	)
	const [selectedCategory, setSelectedCategory] = useState<string | null>(null)

	const location = useLocation()
	const { t } = useTranslation('common')

	const handleOptionClick = (option: ResponsiveOptions) => {
		setSelectedOption(option)
		setSelectedCategory(null)
		// TODO: Make a session cookie that will update the read from StoryBlok
	}

	useEffect(() => {
		setOpen(false)
		setSelectedCategory(null)
		setSelectedOption(ResponsiveOptions.Main)
	}, [location.pathname])

	const openNavigationContent = navigationData.find(
		(item: any) => item.title === selectedOption,
	)
	return (
		<Sheet open={open} onOpenChange={setOpen}>
			<SheetTrigger>
				<Icon className="text-body-md block md:hidden" name="menu" size="xl" />
			</SheetTrigger>
			<SheetContent className="flex flex-row border-0 p-0">
				<div className={cn('h-full w-10 bg-green-90 opacity-80')} />

				<div
					className={cn(
						'relative w-full px-8  pt-6',
						selectedOption !== ResponsiveOptions.Main ? 'pb-0' : 'pb-[40%]',
					)}
				>
					<Icon name="plentyand" size="lg" className="h-[34px] w-[29px]" />
					{/* Content Here */}
					<div
						className={cn(
							'flex h-full w-full flex-col justify-between py-6',
							selectedOption === ResponsiveOptions.Main ? 'flex' : 'hidden',
						)}
					>
						<div>
							<Heading as={'h4'} heading={'Shop'} />

							{navItems.map(({ link, name }) => (
								<ResponsiveNavItem
									key={link}
									name={name}
									handleOptionClick={handleOptionClick}
								/>
							))}
							<ResponsiveNavLinkItem link="/brands" name="Brands" />
						</div>
						<div>
							<ResponsiveNavLinkItem
								link="/customer-portal/overview"
								name={t('customer_portal:sign_up_login')}
								iconName="avatar"
							/>
							<ResponsiveNavLinkItem
								link="/stores"
								name={t('footer:find_a_store')}
								iconName="store"
							/>
							<ResponsiveNavLinkItem link="/faq" name="Help" iconName="help" />

							{/* Language */}
							<div className="flex w-full justify-end">
								<div
									className="group flex cursor-pointer flex-row"
									onClick={() => handleOptionClick(ResponsiveOptions.Language)}
								>
									<div className="flex">
										<Icon name={t('flag')} size="lg" />
										<div className="ml-2 group-hover:underline">
											<Body as="m" body={t('language')} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<SubNavigationMenu
						openNavigationContent={openNavigationContent}
						handleOptionClick={handleOptionClick}
						selectedOption={selectedOption}
						selectedCategory={selectedCategory}
						setSelectedCategory={setSelectedCategory}
					/>
					<LanguageMenu
						handleOptionClick={handleOptionClick}
						selectedOption={selectedOption}
					/>
				</div>
			</SheetContent>
		</Sheet>
	)
}

export default ResponsiveNavbar
