import { Form, useSubmit } from '@remix-run/react'
import { useTranslation } from 'react-i18next'
import Heading from '#app/components/typography/heading'
import { Icon, type IconName } from '#app/components/ui/icon'
import { getLanguageShortHand } from '#app/utils/localisation/lokalisation-utilities'
import { cn } from '#app/utils/misc'
import { ResponsiveNavItem } from './responsive-nav-item'
import { ResponsiveOptions } from './index'

interface LanguageMenuProps {
	selectedOption: ResponsiveOptions
	handleOptionClick: (option: ResponsiveOptions) => void
}

const LanguageMenu = ({
	selectedOption,
	handleOptionClick,
}: LanguageMenuProps) => {
	const languages: { name: string; icon: IconName }[] = [
		{ name: 'English', icon: 'flags/uk' },
		{ name: 'Dansk', icon: 'flags/dk' },
	]
	const submit = useSubmit()
	let { t, i18n } = useTranslation('common')

	const handleSubmit = async (formData: FormData) => {
		try {
			await submit(formData, {
				method: 'POST',
				action: '/resources/language',
			})
		} catch (error) {
			console.log(error)
			console.error(error)
		}
	}

	const handleOnClick = (language: string) => {
		// Change the language using i18n
		i18n.changeLanguage(getLanguageShortHand(language))

		// Get the current URL parameters
		const urlParams = new URLSearchParams(window.location.search)

		// Define the parameters you want to handle
		const paramsToCheck = [
			'color',
			'brand',
			'materials',
			'size',
			'sortingOrder',
		]

		// Check if the necessary params are present and clean them up if necessary
		paramsToCheck.forEach(param => {
			if (urlParams.has(param)) {
				urlParams.delete(param) // Remove the parameter from the URL
			}
		})

		// Add the language parameter to the form data
		const formData = new FormData()
		formData.append('language', getLanguageShortHand(language))

		// Update the URL without reloading the page
		const newUrl = `${window.location.pathname}?${urlParams.toString()}`
		window.history.replaceState({}, '', newUrl)

		// Handle the form submission
		handleSubmit(formData)
	}

	return (
		<Form
			className={cn(
				'flex h-full w-full flex-col  py-6',
				selectedOption === ResponsiveOptions.Language ? 'flex' : 'hidden',
			)}
		>
			<div className="flex gap-2">
				<Icon
					name="arrow-left"
					size="lg"
					className="cursor-pointer"
					onClick={() => handleOptionClick(ResponsiveOptions.Main)} 
				/>
				<Heading
					as={'h2'}
					className="text-shadow text-[32px] leading-normal tracking-[-1.12px] text-black-90 md:text-[48px] md:tracking-[-1.68px]"
					heading={t('select_language')}
				/>
			</div>
			{languages.map(({ name, icon }) => (
				<ResponsiveNavItem
					key={name}
					name={name}
					iconName={icon}
					handleOptionClick={handleOnClick}
					isActive={getLanguageShortHand(name) == i18n.language}
				></ResponsiveNavItem>
			))}
		</Form>
	)
}

export default LanguageMenu
